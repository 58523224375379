<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add Facility</h1>
    </page-header>
    <page-body class>
      <h1>
        {{ translations.tcAddEditCalendarItem }}
        <div class="change-link">{{ translations.tcNew }}</div>
      </h1>
      <section class="section-1 bg-block mb-4">
        <div class="body">
          <header class="toggle-header mb-3">
            <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
            <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
          </header>
          <div v-if="Section1">
            <b-form>
              <div class="row">
                <div class="col-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcTitle }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-input id="title" v-model="calendarItem.Title" class="flex-0" type="text"></b-form-input>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group class="form-element">
                    <div class="date-select-1 mb-1">
                      <label class="font-style-1">{{ translations.tcDay }} *</label>
                    </div>
                    <div class="mb-3">
                      <b-form-select :title="translations.tcDayOfMonth" v-model="calendarItem.Day" :options="optionDays"
                        class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row" v-if="calItem.category === 'Officers' && calItem.level === 'state'">
                <div class="col-12">To add a photograph, click the picture button in the request section below.
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </section>
      <section class="section-4 bg-block mb-4 request-section">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcRequest }} *</h2>
        </header>
        <div class="body" v-if="editorOptions.placeholder === translations.tcEditorPlaceHolder">
          <quill-editor class="request-editor" v-model="calendarItem.Detail" ref="myQuillEditor"
            :options="editorOptions">
          </quill-editor>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcSave }}
            </b-button>
            <b-button v-if="selectedPrayerRequest.type === 'edit'" variant="secondary" @click="handleDeleteClick"
              class="flex-0 mr-3 w-100-sd mb-sm-0">
              {{ translations.tcDelete }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-edit-calendar-item',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      Section1: true,
      presetDate: null,
      errors: [],
      showCalendars: false,
      noOfDays: 1,
      isSaving: false,
      imgUrl: '',
      file: '',
      calendarItem: {},
      editorOptions: { placeholder: '' },
    }
  },
  methods: {
    ...mapActions({
      addPrayerRequest: 'prayerCenter/addPrayerRequest',
      getImageSrcUrl: 'prayerCenter/getImageSrcUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedPrayerRequest: 'prayerCenter/setSelectedPrayerRequest',
      updatePrayerRequest: 'prayerCenter/updatePrayerRequest',
      updateCalendarItem: 'prayerCenter/updateCalendarItem',
      uploadCalendarItemImage: 'prayerCenter/uploadCalendarItemImage',
    }),
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async handleFileUpload() {
      this.setLoadingStatus(true)
      this.file = this.$refs.file.files[0]
      let formData = new FormData()
      formData.append('file', this.file)
      let params = {
        orgKey: this.selectedPrayerRequest.orgKey,
        data: formData,
      }
      let response = await this.uploadCalendarItemImage(params)
      this.calendarItem.imagepath = response.Message
      this.imgUrl = await this.getImageSrcUrl(this.calendarItem.imagepath)
      this.setLoadingStatus(false)
    },
    validate() {
      this.errors = []
      if (this.calendarItem.Title == '') {
        this.errors.push(this.translations.tcTitleIsEmpty)
      }
      if (this.calendarItem.Day <= 0) {
        this.errors.push(this.translations.tcPleaseSelectValidMonthDay)
      }
      if (this.calendarItem.Detail == '') {
        this.errors.push(this.translations.tcDetailIsEmpty)
      }
      return this.errors.length <= 0
    },
    handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        title: this.translations.tcOkayToDelete,
        html: `<i>${this.translations.tcIsThatOkay}</i>`,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel,
      }).then((result) => {
        if (!!result.value) {
          this.submitRequest(this.calendarItem, true)
        }
      })
    },
    handleSaveClick() {
      if (!this.validate()) {
        this.$swal({
          icon: 'error',
          text: this.errors.join(', '),
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return
      }
      this.submitRequest(this.calendarItem, false)
    },
    async submitRequest(data, del_switch) {
      data.Delete = del_switch
      let params = {
        orgKey: this.selectedPrayerRequest.orgKey,
        data: data,
      }
      try {
        this.setLoadingStatus(true)
        let response = false
        if (this.edit) {
          response = await this.updateCalendarItem(params)
        } else {
          response = await this.addPrayerRequest(params)
        }
        this.setLoadingStatus(false)
        if (response == true) {
          this.$swal({
            icon: 'success',
            text: del_switch
              ? this.translations.tcRequestDeletedSuccessfully
              : this.translations.tcRequestAddedSuccessfully,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          if (del_switch) {
            this.clearCalendarItem()
            await this.setSelectedPrayerRequest({
              orgKey: null,
              cprKey: null,
              type: this.edit ? 'edit' : 'add',
            })
          }
          this.$router.push({
            name: 'prayer-manage-calendar',
          })
          return
        } else {
          throw 'edit falied'
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: del_switch ? this.translations.tcErrorDeletingRequest : this.translations.tcErrorAddingRequest,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
    },
    handleCancelClick() {
      this.$router.push({
        name: 'prayer-manage-calendar',
      })
    },
    async pageLoad() {
      if (!!this.selectedPrayerRequest.details) {
        this.calendarItem.Key = this.selectedPrayerRequest.details.prr_key
        this.calendarItem.Title = this.selectedPrayerRequest.details.title
        this.calendarItem.Detail = this.selectedPrayerRequest.details.detail
        this.calendarItem.Day = this.selectedPrayerRequest.details.day
        this.calendarItem.IndKey = this.selectedPrayerRequest.details.prr_ind_key
          ; (this.calendarItem.User = this.userId),
            (this.calendarItem.ImagePath = this.selectedPrayerRequest.details.img_image_path)
        this.imgUrl = await this.getImageSrcUrl(this.selectedPrayerRequest.details.img_image242_path)
      } else {
        this.calendarItem.Key = this.selectedPrayerRequest.cprKey
        this.calendarItem.User = this.userId
      }
    },
    loadTranslations() {
      this.editorOptions.placeholder = this.translations.tcEditorPlaceHolder
    },
    clearCalendarItem() {
      this.calendarItem = {
        Title: '',
        Key: '',
        Detail: '',
        ImagePath: '',
        IndKey: '',
        User: '',
        Day: 0,
        Delete: false,
      }
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.clearCalendarItem()
      await Promise.all([this.getViewTranslations(), this.pageLoad()]).then(() => {
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      calItem: 'prayerCenter/getCalendarItem',
      prefCulture: 'user/userPreferredCulture',
      selectedPrayerRequest: 'prayerCenter/selectedPrayerRequest',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userLanguageKey: 'user/userLanguageKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
    }),
    edit() {
      return !!this.selectedPrayerRequest.details
    },
    optionDays() {
      let currentDay = new Date()
      let lastDay = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, 0)
      let arrays = Array.from({ length: lastDay.getDate() }, (_, i) => i + 1)
      return arrays
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    quillEditor,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style scoped>
.ql-editor {
  font-size: 18px;
}
</style>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.request-section {
  height: 400px;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.request-editor {
  height: 150px;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
